import React from "react";
import styled from "styled-components";
import { Container } from "../Grid";

const ScMain = styled.div`
  padding-top: 6rem;
  padding-bottom: 4rem;

  @media screen and (min-width: 992px) {
    padding-top: 200px;
    padding-bottom: 200px;
  }
`;

const ScTitle = styled.h3`
  text-align: center;
  margin-bottom: 3rem;
  font-weight: 700;
  font-size: 32px;

  @media screen and (min-width: 992px) {
    font-size: 44px;
    margin-bottom: 64px;
  }
`;

const ScBkList = styled.div`
  @media screen and (min-width: 992px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -8px;
    margin-right: -8px;
  }
`;
const ScBkItem = styled.div`
  margin-bottom: 1rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  img {
    width: 80%;
  }

  @media screen and (min-width: 992px) {
    width: calc(33% - 16px);
    margin-left: 48px;
    margin-right: 48px;
    margin-bottom: 36px;

    img {
      width: 100%;
    }
  }
`;

export const Backer = () => {
  return (
    <ScMain>
      <Container>
        <ScTitle>Our backers</ScTitle>
        <ScBkList>
          <ScBkItem style={{ width: 200 }}>
            <img src="/images/v5/investors/in-framework.png" alt="Framework" />
          </ScBkItem>
          <ScBkItem style={{ width: 310 }}>
            <img src="/images/v5/investors/in-pantera.png" alt="Pantera" />
          </ScBkItem>
          <ScBkItem style={{ width: 210 }}>
            <img src="/images/v5/investors/in-qcp.png" alt="QCP Capital" />
          </ScBkItem>
        </ScBkList>
        <ScBkList>
          <ScBkItem style={{ width: 90 }}>
            <img src="/images/v5/investors/in-box1.png" alt="Box 1" />
          </ScBkItem>
          <ScBkItem style={{ width: 100 }}>
            <img src="/images/v5/investors/in-avv.png" alt="AVV" />
          </ScBkItem>
          <ScBkItem style={{ width: 120 }}>
            <img src="/images/v5/investors/in-cms.png" alt="CMS" />
          </ScBkItem>
          <ScBkItem style={{ width: 220 }}>
            <img
              src="/images/v5/investors/in-ledger-prime.png"
              alt="Ledger Prime"
            />
          </ScBkItem>
        </ScBkList>
      </Container>
    </ScMain>
  );
};
