import React from "react";
import styled from "styled-components";
import { Container } from "../../components/Grid";

const ScMain = styled.div``;

const ScList = styled.div`
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: -40px;
    bottom: -40px;
    width: 2px;
    background: white;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 5%,
      rgba(255, 255, 255, 0.2) 95%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &:after {
    position: absolute;
    top: 99px;
    width: 120%;
    height: 2px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 1px;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 5%,
      rgba(255, 255, 255, 0.2) 95%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  @media (min-width: 920px) {
    display: flex;

    &:before {
      content: none;
    }

    &:after {
      content: "";
    }
  }
`;
const ScItem = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  @media (min-width: 920px) {
    display: block;
    margin-bottom: 0;
  }
`;
const ScItemTime = styled.div`
  font-size: 14px;
  line-height: 1.4;
  color: #868f97;
  padding-left: 12px;
  height: 20px;
  width: 60px;
  flex: none;

  @media (min-width: 920px) {
    width: 100%;
    padding-left: 0;
    text-align: center;
    font-size: 14px;
    line-height: 1.4;
    color: #868f97;
    height: 20px;
    margin-bottom: 24px;
  }
`;
const ScItemIcon = styled.div`
  position: relative;
  margin-right: 12px;
  flex: none;

  svg {
    width: 32px;
    height: 32px;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    background: #8c8c8f;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    top: 12px;
    left: -63px;
  }

  @media (min-width: 920px) {
    text-align: center;
    position: relative;
    margin-bottom: 44px;
    margin-right: 0;

    svg {
      width: 32px;
      height: 32px;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      background: #8c8c8f;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      top: 52px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;
const ScItemTitle = styled.div`
  font-size: 16px;
  line-height: 1.4;
  color: #868f97;

  @media (min-width: 920px) {
    max-width: 160px;
    font-size: 16px;
    line-height: 1.4;
    text-align: center;
    color: #868f97;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const Timeline = () => {
  return (
    <ScMain>
      <Container>
        <ScList>
          <ScItem>
            <ScItemTime>2020</ScItemTime>
            <ScItemIcon>
              <svg
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity=".15"
                  width="32"
                  height="32"
                  rx="7"
                  fill="#B4F002"
                />
                <path
                  d="M20.525 10.177a.34.34 0 0 0-.534.164 6.965 6.965 0 0 1-1.644 2.598c.034-.69.002-1.38-.097-2.063-.317-2.162-1.314-3.831-2.883-4.823a.342.342 0 0 0-.525.289c0 1.478-.796 2.964-2.297 4.298-1.377 1.223-4.579 4.455-4.545 8.025.024 2.524 1.377 6.023 4.818 7.335.133-1.418.765-2.463 1.38-3.476.654-1.073 1.271-2.086 1.329-3.538a.341.341 0 0 1 .581-.232c1.771 1.728 2.721 4.148 2.83 7.2 2.449-.947 4.477-3.101 4.77-6.652.344-4.155-.727-7.225-3.183-9.125Z"
                  fill="#B4F002"
                />
              </svg>
            </ScItemIcon>
            <ScItemTitle>PTF Token Launch</ScItemTitle>
          </ScItem>
          <ScItem>
            <ScItemTime>2021</ScItemTime>
            <ScItemIcon>
              <svg
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity=".15"
                  width="32"
                  height="32"
                  rx="7"
                  fill="#B4F002"
                />
                <path
                  d="M20.5 6h-10A2.5 2.5 0 0 0 8 8.5v15a2.5 2.5 0 0 0 2.5 2.5h10a2.5 2.5 0 0 0 2.5-2.5v-15A2.5 2.5 0 0 0 20.5 6Zm.833 16.667a.834.834 0 0 1-.833.833h-10a.833.833 0 0 1-.833-.833V9.333A.833.833 0 0 1 10.5 8.5h10a.833.833 0 0 1 .833.833v13.334Z"
                  fill="#B4F002"
                />
                <path
                  d="M15.5 21.833a.833.833 0 1 0 0-1.666.833.833 0 0 0 0 1.666Z"
                  fill="#B4F002"
                />
              </svg>
            </ScItemIcon>
            <ScItemTitle>Mobile App Launch</ScItemTitle>
          </ScItem>
          <ScItem>
            <ScItemTime>2022</ScItemTime>
            <ScItemIcon>
              <svg
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity=".15"
                  width="32"
                  height="32"
                  rx="7"
                  fill="#00F0FF"
                />
                <path
                  d="M21.113 18.21a4.748 4.748 0 1 0 0-9.497 4.748 4.748 0 0 0 0 9.497ZM16.365 18.21h-6.043a.863.863 0 0 0-.864.863v6.044c0 .476.387.863.864.863h6.043a.863.863 0 0 0 .863-.863v-6.044a.863.863 0 0 0-.863-.863ZM14.58 14.543a.433.433 0 0 0 0-.432l-3.885-6.907a.45.45 0 0 0-.753 0l-3.885 6.907a.432.432 0 0 0 .38.646h7.77a.431.431 0 0 0 .373-.214Z"
                  fill="#00F0FF"
                />
              </svg>
            </ScItemIcon>
            <ScItemTitle>Full Derivatives Suite Multi-Collateral</ScItemTitle>
          </ScItem>
          <ScItem>
            <ScItemTime></ScItemTime>
            <ScItemIcon>
              <svg
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity=".15"
                  width="32"
                  height="32"
                  rx="7"
                  fill="#00F0FF"
                />
                <path
                  d="M18 6h-8c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V12l-6-6Zm-8 18V8h7v4h5v12H10Zm5-1h2v-1h1c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1h-3v-1h4v-2h-2v-1h-2v1h-1c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h3v1h-4v2h2v1Z"
                  fill="#00F0FF"
                />
              </svg>
            </ScItemIcon>
            <ScItemTitle>RFQ/ Multi-Leg</ScItemTitle>
          </ScItem>
          <ScItem>
            <ScItemTime></ScItemTime>
            <ScItemIcon>
              <svg
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity=".15"
                  width="32"
                  height="32"
                  rx="7"
                  fill="#00F0FF"
                />
                <path
                  d="M24.182 6H7.818A1.818 1.818 0 0 0 6 7.818v14.546c0 1.004.814 1.818 1.818 1.818h16.364A1.818 1.818 0 0 0 26 22.364V7.818A1.818 1.818 0 0 0 24.182 6Z"
                  stroke="#00F0FF"
                  strokeMiterlimit="10"
                  strokeLinecap="square"
                />
                <path
                  d="m14.715 13.806-2.571-2.571M19.857 18.948l-2.572-2.572M17.285 13.806l2.572-2.571M12.144 18.948l2.57-2.572"
                  stroke="#00F0FF"
                  strokeMiterlimit="10"
                />
                <path
                  d="M16 20.545a5.454 5.454 0 1 0 0-10.908 5.454 5.454 0 0 0 0 10.908Z"
                  stroke="#00F0FF"
                  strokeMiterlimit="10"
                  strokeLinecap="square"
                />
                <path
                  d="M16 16.91a1.818 1.818 0 1 0 0-3.637 1.818 1.818 0 0 0 0 3.636ZM9.636 24.182V26M22.364 24.182V26"
                  stroke="#00F0FF"
                  strokeMiterlimit="10"
                  strokeLinecap="square"
                />
              </svg>
            </ScItemIcon>
            <ScItemTitle>Copper/ Clearloop Integration</ScItemTitle>
          </ScItem>
          <ScItem>
            <ScItemTime>2023</ScItemTime>
            <ScItemIcon>
              <svg
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity=".14"
                  width="32"
                  height="32"
                  rx="7"
                  fill="#FF3D00"
                />
                <path
                  d="m17 12-3 4h4l-3 4"
                  stroke="#FF3D00"
                  strokeMiterlimit="10"
                  strokeLinecap="square"
                />
                <path
                  d="m11 5-3 5v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V10l-3-5"
                  stroke="#FF3D00"
                  strokeMiterlimit="10"
                />
                <path
                  d="M23 5H9"
                  stroke="#FF3D00"
                  strokeMiterlimit="10"
                  strokeLinecap="square"
                />
              </svg>
            </ScItemIcon>
            <ScItemTitle>$320M/ Monthly Volume</ScItemTitle>
          </ScItem>
          <ScItem>
            <ScItemTime></ScItemTime>
            <ScItemIcon>
              <svg
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity=".14"
                  width="32"
                  height="32"
                  rx="7"
                  fill="#FF3D00"
                />
                <path
                  d="m19.06 7.717 5.2 5.2M17.02 8.849a6.997 6.997 0 0 0-7.715 1.48c-.428.427-.781.9-1.075 1.399l2.997 2.998M23.137 14.934a6.994 6.994 0 0 1-1.466 7.761c-.427.428-.9.781-1.399 1.075l-3.01-3.01"
                  stroke="#FF3D00"
                  strokeMiterlimit="10"
                />
                <path
                  d="M14.87 22.077 9.923 17.13S14.148 6.618 26 6c-.67 11.8-11.13 16.077-11.13 16.077Z"
                  stroke="#FF3D00"
                  strokeMiterlimit="10"
                  strokeLinecap="square"
                />
                <path
                  d="M19.48 14.994a1.749 1.749 0 1 0-2.474-2.473 1.749 1.749 0 0 0 2.473 2.473ZM6.973 21.491a2.486 2.486 0 1 1 3.517 3.517C9.52 25.98 6 26 6 26s.002-3.538.973-4.509Z"
                  fill="#FF3D00"
                />
              </svg>
            </ScItemIcon>
            <ScItemTitle>$100M/ Daily Volume</ScItemTitle>
          </ScItem>
        </ScList>
      </Container>
    </ScMain>
  );
};
