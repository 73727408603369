import * as React from "react";
import { graphql, HeadFC } from "gatsby";

import { Backer } from "../components/Backer/Backer";
import { Layout } from "../components/Layout";
import { ScHeader } from "../containers/about/header";
import { Onboard } from "../containers/about/onboard";
import { Timeline } from "../containers/about/timeline";

export const query = graphql`
  query getImageAndGetImage {
    platform: file(relativePath: { eq: "v5/about/pt-platform.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const InstitutionalPage = ({ data }: any) => {
  return (
    <Layout>
      <ScHeader data={data} />
      <Timeline />
      <Backer />
      <Onboard />
    </Layout>
  );
};

export default InstitutionalPage;

export const Head: HeadFC = () => <title>About Us</title>;
