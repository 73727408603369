import React from "react";
import styled from "styled-components";

import { Container } from "../../components/Grid";
import {
  ScHelper,
  ScTitle,
  ScWhiteButton,
  ScVerticalDiv,
} from "../../components/Shared";

const ScRoot = styled.div`
  padding-top: 2rem;
  padding-bottom: 4rem;
  background: #0e0e13;

  @media screen and (min-width: 992px) {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
`;

const ScMain = styled.div`
  text-align: center;
`;

export const Onboard = () => {
  return (
    <ScRoot>
      <Container>
        <ScVerticalDiv />
        <ScMain>
          <ScTitle>Get started now</ScTitle>
          <ScHelper>Start trading in a few minutes</ScHelper>
          <ScWhiteButton href="https://app.power.trade/signup" target="_blank">
            Sign Up
          </ScWhiteButton>
        </ScMain>
      </Container>
    </ScRoot>
  );
};
