import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

import { Container } from "../../components/Grid";

import {
  ScTitle as ScTitleSrc,
  ScHelper as ScHelperSrc,
} from "../../components/Shared";

const ScMain = styled.div`
  margin-top: 5rem;
  margin-bottom: 3rem;

  @media screen and (min-width: 992px) {
    margin-top: 210px;
    margin-bottom: 6rem;
  }
`;

const ScTitle = styled(ScTitleSrc)`
  text-align: center;

  @media screen and (min-width: 992px) {
    line-height: 52px;
  }
`;
const ScHelper = styled(ScHelperSrc)`
  text-align: center;
  max-width: 650px;
  color: #868f97;

  @media screen and (min-width: 992px) {
    font-size: 18px;
  }
`;
const ScCover = styled(Img)`
  text-align: center;
  margin-bottom: 4rem;
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 992px) {
    margin-top: 40px;
    margin-bottom: 1rem;
  }
`;

const ScEstablished = styled.div`
  @media screen and (min-width: 992px) {
    display: flex;
    align-items: center;
    margin-top: -20px;
  }
`;
const ScEstablishedLeft = styled.div`
  flex: 6;
  margin-bottom: 2rem;

  img {
    max-width: 140px;
    display: block;
  }

  @media screen and (min-width: 992px) {
    margin-bottom: 0rem;
    margin-right: 12px;

    img {
      max-width: 520px;
      margin-left: auto;
      margin-right: initial;
    }
  }
`;
const ScEstablishedRight = styled.div`
  flex: 4;
`;

const ScEstablishedContent = styled.div`
  & > h6 {
    margin: 0;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 1.5px;
    color: #b4f002;
  }

  & > p {
    margin-top: 12px;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.4;
    color: #868f97;
    max-width: 500px;
  }

  @media screen and (min-width: 992px) {
    margin-top: -36px;
    margin-left: -48px;
  }
`;

export const ScHeader = ({ data }: any) => {
  return (
    <ScMain>
      <Container>
        <ScTitle>A new frontier of professional crypto&nbsp;trading</ScTitle>
        <ScHelper>
          At PowerTrade, we're transforming professional trading with
          innovative, accessible, and secure solutions for institutions and
          expert traders. Our dedication to excellence and continuous
          improvement fuels our pursuit of outstanding customer experiences and
          lasting partnerships. Join us in navigating the new frontier of
          finance.
        </ScHelper>
        <ScCover fluid={data.platform.childImageSharp.fluid} />

        <ScEstablished>
          <ScEstablishedLeft>
            <img src="/images/v5/about/pt-bolt.png" alt="" />
          </ScEstablishedLeft>
          <ScEstablishedRight>
            <ScEstablishedContent>
              <h6>ESTABLISHED 2020</h6>
              <p>
                PowerTrade was established in 2020 by Mario Gomez Lozada, an
                industry veteran and the founder of Liquid. Identifying the
                untapped potential and growing demand for crypto options, Mario
                assembled a team of experts and seasoned professionals to create
                a platform tailored to the unique requirements of institutions
                and professional traders.
              </p>
            </ScEstablishedContent>
          </ScEstablishedRight>
        </ScEstablished>
      </Container>
    </ScMain>
  );
};
